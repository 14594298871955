/* General Styles */
.admin-page {
    background-color: #f8f9fa;
    font-family: Arial, sans-serif;
    color: #333;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.admin-content {
    flex: 1;
    padding: 20px 40px;
    background-color: white;
    margin: 20px auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-width: 1200px;
}

/* Back to Cabinet Button */
.back-cabinet-btn {
    display: block;
    margin: 20px auto;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.back-cabinet-btn:hover {
    background-color: #0056b3;
}

/* User Menu Styling */
.admin-menu {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    margin-top: 30px;
    flex-wrap: wrap;
}

/* Button Styling */
.admin-menu-btn {
    background-color: transparent;
    border: 2px solid rgba(18, 22, 25, 0.7);
    color: rgba(18, 22, 25, 0.8);
    font-size: 16px;
    padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-family: inherit;
    width: 120px;
}
/* Settings Button Specific */
.admin-btn {
    border-color: rgba(0, 123, 255, 0.7);
    color: rgba(0, 123, 255, 0.8);
}

.admin-btn:hover {
    background-color: rgba(0, 123, 255, 0.9);
    color: #ffffff;
    border-color: rgba(0, 123, 255, 1);
}

/* Button Hover Effect */
.admin-menu-btn:hover {
    background-color: rgba(18, 22, 25, 0.9);
    color: #ffffff;
    border-color: rgba(18, 22, 25, 1);
}

/* Responsive Design */
@media (max-width: 768px) {
    .admin-content {
        padding: 15px;
    }

    .back-cabinet-btn {
        padding: 8px 16px;
        font-size: 14px;
    }
}

@media (max-width: 520px) {
    .admin-content {
        padding: 10px;
    }

    .back-cabinet-btn {
        padding: 6px 12px;
        font-size: 12px;
    }
}

