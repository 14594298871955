.support-page {
    background-color: #f9f9f9;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
}

.support-section {
    max-width: 800px;
    margin: 30px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.support-title {
    font-size: 36px;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.support-content h4, .support-content h5 {
    margin-top: 20px;
    font-size: 24px;
    color: #444;
    border-bottom: 2px solid #007BFF;
    display: inline-block;
    padding-bottom: 5px;
}

.support-content ul {
    list-style-type: disc;
    padding-left: 20px;
    margin: 10px 0;
}

.support-content ul li {
    margin-bottom: 10px;
}

.support-content p {
    margin-top: 15px;
    font-size: 16px;
    color: #555;
    line-height: 1.8;
}

.support-content p strong {
    color: #000;
}

.support-content a {
    color: #007BFF;
    text-decoration: underline;
    cursor: pointer;
}

.support-content a:hover {
    color: #0056b3;
}

/* Table Styling */
.support-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.support-table th, .support-table td {
    border: 1px solid #ddd;
    padding: 10px 15px;
    text-align: center;
}

.support-table th {
    background-color: #007BFF;
    color: #ffffff;
    font-size: 1.2rem;
}

.support-table td {
    font-size: 1rem;
    color: #333;
}

.support-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.support-table tr:hover {
    background-color: #e9f5ff;
}
/* Responsive Design: Small screens (e.g., tablets and mobile) */
@media (max-width: 520px) {
    .support-table th, .support-table td {
        padding: 2px;   /* Slightly reduced padding for better fit */
        font-size: 16px; /* Smaller text size on mobile */
    }

    .support-table {
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}
@media (max-width: 320px) {
    .support-table th, .support-table td {
        padding: 2px;   /* Slightly reduced padding for better fit */
        font-size: 8px; /* Smaller text size on mobile */
    }

}
