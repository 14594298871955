.pricing-section {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
}

.card {
    position: relative;
    width: 300px;
    height: 250px;
    background-color: #f2f2f2;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    perspective: 1000px;
    box-shadow: 0 0 0 5px #ffffff80;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.card__icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.card__icon {
    width: 48px;
    fill: #333;
}

.card__text {
    margin-top: 8px; /* Space between text and icon */
    font-size: 16px;
    color: #333;
    font-weight: bold;
}

.card__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f2f2f2;
    transform: rotateX(-90deg);
    transform-origin: bottom;
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card:hover .card__icon-container {
    opacity: 0;
}

.card:hover .card__content {
    transform: rotateX(0deg);
    opacity: 1;
}

.card__title {
    margin: 0;
    font-size: 24px;
    color: #333;
    font-weight: 700;
}

.card__description {
    margin: 10px 0 0;
    font-size: 14px;
    color: #777;
    line-height: 1.4;
}

.card:hover {
    box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
}
