/* Back Button */
.back-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px 20px;
    background: linear-gradient(135deg, #007bff 0%, #07ABB7FF 100%);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}
.back-button:hover {
    background: linear-gradient(0deg, #07ABB7FF 0%, #007bff 100%);
    transform: translateY(-3px); /* Subtle upward movement */
}

/* Responsive Design */
@media (max-width: 768px) {
    .back-button {
        font-size: 14px;
        padding: 8px 16px;
        bottom: 15px; /* Adjust spacing from the bottom */
        right: 15px; /* Adjust spacing from the right */
    }
}

@media (max-width: 480px) {
    .back-button {
        font-size: 12px;
        padding: 6px 12px;
        bottom: 10px; /* Further adjustment for very small screens */
        right: 10px;
        border-radius: 4px; /* Slightly smaller border radius */
    }
}