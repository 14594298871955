.faq-page {
    background-color: #fff;
    font-family: Arial, sans-serif;
}

.faq-section {
    max-width: 900px;
    margin: 30px auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.faq-title {
    font-size: 36px;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.faq-wrapper {
    overflow: hidden;
}

.faq-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 15px;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
}

.faq-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.3s ease;
}

.faq-question {
    font-size: 20px;
    color: #333;
}

.faq-expand-icon {
    display: flex;
    align-items: center;
}

.expand-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.expand-btn svg {
    transition: transform 0.3s ease;
}

.faq-details {
    max-height: 0;
    overflow: hidden;
    padding: 0 15px;
    background-color: #f1f1f1;
    transition: max-height 0.5s ease, padding 0.5s ease;
    overflow-y: auto; /* Added for scrolling */
}

.faq-details.show {
    max-height: 1000px; /* Set high enough to accommodate most content, or use a fixed value */
    padding: 15px;
}

.faq-item.open .faq-summary {
    background-color: #e2e6ea;
}

.faq-item.open .expand-btn svg {
    transform: rotate(180deg); /* Rotate for collapse icon */
}
