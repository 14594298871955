/* General container setup */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7f7f7;
  padding: 20px;
}

/* Receipt style */
.receipt {
  width: 100%;
  max-width: 800px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  padding: 40px;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

.receipt:hover {
  transform: scale(1.03);
}

/* Title and date */
.paper-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title-receipt {
  font-size: 36px;
  font-weight: bold;
  color: #2c3e50;
}

.date {
  font-size: 18px;
  color: #7f8c8d;
}

/* Company Information */
.company-info {
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 1.6;
  color: #7f8c8d;
}

.company-info p {
  margin: 5px 0;
}

/* Table Style */
table-success {
  width: 100%;
  margin-top: 30px;
  border-collapse: collapse;
}

table-success th, table-success td {
  padding: 12px 20px;
  text-align: left;
}

table-success th {
  background-color: #f1f1f1;
  color: #7f8c8d;
  font-weight: bold;
}

.successtbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.right {
  text-align: right;
  color: #2c3e50;
}

.successtbody tr td {
  font-size: 16px;
  color: #34495e;
}

/* Thank You Section */
.thank-you {
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
  font-weight: bold;
  color: #27ae60;
}

.date-time {
  text-align: center;
  font-size: 14px;
  color: #7f8c8d;
  margin-top: 10px;
}

/* Buttons */
.buttons-success {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.button-success {
  background-color: #3498db;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 48%;
}

.button-success:hover {
  background-color: #2980b9;
  transform: scale(1.05);
}

.button-success:active {
  transform: scale(1);
}

/* Loader styling */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.policy-info {
  text-align: center;
  margin-top: 20px;

}
.policy-a-right{
  margin-right: 20px;
}
.policy-a-left{
  margin-left: 20px;
 }

/* Responsive design */
@media (max-width: 768px) {
  .receipt {
    padding: 20px;
  }

  .paper-title-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .title-receipt {
    font-size: 28px;
  }

  .date {
    font-size: 16px;
  }

  table-success th, table-success td {
    font-size: 14px;
    padding: 8px 15px;
  }

  .buttons {
    flex-direction: column;
  }

  .buttons-success .button-success {
    width: 100%;
    margin-bottom: 10px;
  }

  .thank-you {
    font-size: 16px;
  }
}
