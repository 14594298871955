.btn {
    position: relative;
    width: 11rem;
    height: 3.5rem;
    border: none;
    border-radius: 3rem;
    cursor: pointer;
    background: linear-gradient(to top, #ccc 0%, #ccc 100%);
    font-size: 1.2rem;
    font-weight: 550;
    font-family: sans-serif;
    letter-spacing: 1px;
    transition: all 0.3s;


}

.btn::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    border-radius: 3rem;
    width: 100%;
    height: 100%;
    padding: 2px;
    background: linear-gradient(135deg, #007bff 0%, #07ABB7FF 100%);
    z-index: -1;
}

.btn:hover {
    background: #315cfd;
    background: linear-gradient(135deg, #007bff 0%, #07ABB7FF 100%);
    color: white;
    font-size: 1.3rem;
}