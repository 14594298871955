.new-pricing-section {
    display: flex;
    flex-wrap: wrap; /* Keeps the wrapping behavior on smaller screens */
    justify-content: center;
    gap: 20px;
    /*padding: 20px 20px;*/
    max-width: 100%; /* Ensure the container stays within full width */
}

.new-card {
    background-color: #f2f2f2;
    box-shadow: 0 0 0 5px #ffffff80;
    border-radius: 10px;
    width: calc(21% - 20px); /* 25% to fit four items per row */
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
}

.new-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.new-card__icon-container {
    background-color: #f0f0f0;
    border-radius: 50%;
    padding: 20px;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.new-card__icon {
    width: 50px;
    height: 50px;
}

.new-card__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.new-card__title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
}

.new-card__price {
    font-size: 18px;
    color: #25acb6;
    font-weight: bold;
}

.new-card__description {
    font-size: 14px;
    color: #555;
    line-height: 1.6;
}

@media (max-width: 1200px) {
    .new-card {
        width: calc(33.33% - 20px); /* 3 cards per row on medium screens */
    }
}

@media (max-width: 768px) {
    .new-card {
        width: calc(50% - 20px); /* 2 cards per row on smaller screens */
    }
}

@media (max-width: 576px) {
    .new-card {
        width: 100%; /* 1 card per row on mobile devices */
    }
}
