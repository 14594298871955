.progress-section {
    margin: 40px auto;
    text-align: center;
}

.progress-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 40px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.progress-bar-line {
    position: absolute;
    top: 50%;
    left: 0;
    height: 4px;
    background-color: #007bff;
    z-index: -1;
    transition: width 0.3s ease;
    transform: translateY(-50%);
}

.progress-step {
    position: relative;
    cursor: pointer;
    transition: transform 0.3s;
    flex: 1;
    text-align: center;
    z-index: 1;
}

.progress-step .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ccc;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-size: 18px;
    transition: background-color 0.3s;
}

.circle-title {
    font-family: "Poppins-SemiBold", sans-serif;
    display: block;
    margin-top: 10px;
    font-size: 14px;
    color: #333;
    word-wrap: break-word; /* Allow long titles to break to the next line */
}

/* Make the font size smaller and wrap titles on smaller screens */
@media (max-width: 600px) {
    .progress-step .circle {
        width: 35px;
        height: 35px;
        font-size: 16px;
    }

    .circle-title {
        font-size: 12px;
        margin-top: 8px;
        white-space: normal; /* Allow titles to wrap onto two lines if needed */
    }
}

/* Hide circle-title for screens smaller than 400px */
@media (max-width: 400px) {
    .circle-title {
        display: none; /* Hide titles on very small screens */
    }
}

.progress-step.active .circle {
    background: linear-gradient(135deg, #007bff 0%, #07ABB7FF 100%);
}

.step-info {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.5s ease, opacity 0.5s ease;
}

.step-info.fade-in {
    max-height: 1000px; /* Adjusted to ensure sufficient space for content */
    opacity: 1;
}

.step-info.fade-out {
    opacity: 0;
    max-height: 0;
}

.step-info h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.step-info p {
    font-size: 16px;
    line-height: 1.6;
}
