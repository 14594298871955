/* Styles for Login Form */

/* Styles for the disclaimer message */
.disclaimer-message {
    position: fixed;
    top: 50%;
    right: -100%;  /* Initially hidden off-screen */
    transform: translateY(-50%);  /* Vertically center it */
    padding: 20px;
    background-color: #007bff;
    color: #333;
    font-size: 16px;
    font-family: 'Arial', sans-serif;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: right 0.5s ease-in-out; /* Smooth slide-in transition */
    z-index: 1000;
    max-width: 300px;  /* Maximum width for the message */
    width: 100%;  /* Make it responsive */
    box-sizing: border-box;
    margin-right: 20px;  /* Add some margin for better spacing on the right */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Ensure the message has a better appearance on smaller screens */
@media (max-width: 480px) {
    .disclaimer-message {
        top: 10%;
        font-size: 14px;  /* Smaller text for small screens */
        padding: 15px;  /* Less padding on small screens */
        max-width: 80%;  /* Limit width for small screens */
        right: -80%;  /* Initially hide it off-screen but allow it to slide in */
    }
}

/* When the disclaimer should appear */
.disclaimer-message.show {
    right: 10px;  /* Slide to the right side */
}

/* Ensure that the disclaimer doesn't distort or overlap */
@media (max-width: 768px) {
    .disclaimer-message {
        top: 10%;
        right: -100%;
        max-width: 90%;  /* Expand width to cover more of the screen */
        font-size: 15px;  /* Slightly larger font for readability */
    }
}

@media (min-width: 768px) {
    .disclaimer-message {
        font-size: 16px;  /* Default font size for larger screens */
    }
}

/* Close button styles */
.close-btn {
    background: transparent;
    border: none;
    font-size: 20px;
    color: #FFF;
    cursor: pointer;
    padding: 0 10px;
    transition: color 0.3s ease;
}

.close-btn:hover {
    color: #333;  /* Change color on hover */
    font-weight: bold;

}

/* Add smooth transition for the close button when the message is removed */
.disclaimer-message.hide {
    display: none;
}

.disclaimer-message-text {
    color: white;  /* Slide to the right side */
}


.login-form {
    max-width: 400px;
    min-height: 475px;
    margin: auto;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.login-form h2 {
    font-size: 32px;
    margin-bottom: 20px;
    color: #333;
}

.login-form img {
    width: 130px;
    height: 130px;
    margin-bottom: 30px;
}

.user-box {
    position: relative;
    margin-bottom: 25px;
}

.user-box input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: #333;
    background: #f5f5f5;
    border: none;
    border-radius: 4px;
    outline: none;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
}

.user-box label {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #999;
    pointer-events: none;
    transition: 0.3s ease;
}

.user-box input:focus ~ label,
.user-box input:not(:placeholder-shown) ~ label {
    top: -18px;
    left: 10px;
    color: #333;
    font-size: 12px;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
}

.submit-btn {
    position: relative;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #0056b3;
}

.submit-btn span {
    position: absolute;
    display: block;
    width: 25%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform: skewX(-20deg);
    transition: 0.5s;
}

.submit-btn span:nth-child(1) {
    top: 0;
    left: -100%;
    transition-delay: 0s;
}

.submit-btn span:nth-child(2) {
    top: 0;
    right: -100%;
    transition-delay: 0.25s;
}

.submit-btn span:nth-child(3) {
    bottom: 0;
    right: -100%;
    transition-delay: 0.5s;
}

.submit-btn span:nth-child(4) {
    bottom: 0;
    left: -100%;
    transition-delay: 0.75s;
}

.submit-btn:hover span {
    left: 0;
    right: 0;
}

.submit-btn:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}

.logout-link {
    display: inline-block;
    margin-top: 20px;
    color: #007bff;
    font-family: "Poppins-SemiBold";
    cursor: pointer;
}

.logout-link:hover {
    text-decoration: underline;
}

.error-message {
    color: red;
    font-size: 0.875rem;
    position: relative;
    transform: translateY(-50%);
}

.forgot-password-link,
.recovery-token-link {
    margin-top: 5%;
}

.forgot-password-link a,
.recovery-token-link a {
    color: #007bff;
    text-decoration: none;
}

.forgot-password-link  a:hover,
.recovery-token-link a:hover {
    text-decoration: underline;
}
