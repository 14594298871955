/* FooterSection.css */

footer {
    width: 100%;
    background-color: #121619;
    color: #fff;
}
.footer-waves {
    transform: rotate(180deg);
}

footer .top_header {
    display: flex;
    align-items: center;
    justify-content: center;
}

footer .top_header section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

footer .top_header span {
    padding: 0 1rem;
}

footer .bottom_content {
    display: flex;
    justify-content: center;
    padding: 1.5rem 0;
    flex-direction: column;
}

footer .bottom_content section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 2rem;
}

footer .bottom_content .aLink{
    margin: 0 20px;
    color: rgba(255, 255, 255, 0.7);
    transition: color 0.5s;
}

footer .bottom_content .aLink:hover {
    color: rgba(255, 255, 255, 1);
}

footer .copyright {
    padding: 0.8em 0;
    text-align: center;
    color: white;
    font-size: 12px;
    position: relative;
}

.border-shape {
    background: #353c462e none repeat scroll 0 0;
    color: #fff;
    display: block;
    height: 1px;
    left: 0;
    margin: 1px auto;
    position: relative;
    right: 0;
    text-align: center;
    top: 0;
    width: 35%;
}

/* Responsive Design */
@media (max-width: 820px) {
    footer .top_header {
        display: block;
        padding: 1rem;
    }

    footer .top_header section {
        margin: 20px 0;
        justify-content: center;
    }

    footer .bottom_content {
        flex-direction: column;
        align-items: center;
    }

    footer .bottom_content section {
        padding: 1rem 0;
    }

    footer .links {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 15px;
    }

    footer .bottom_content .aLink{
        margin: 5px 10px;
        font-size: 12px;
        display: inline-block;
    }
}
.aLink{
    text-decoration: none;
    outline: none !important;
    color: #fff;
}
/* Footer Buttons */
footer .links button {
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.7);
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-family: inherit; /* Match font with footer */
}

footer .links button:hover {
    background-color: rgba(255, 255, 255, 0.9);
    color: #121619;
    border-color: rgba(255, 255, 255, 0.9);
}

/* Cabinet Button */
footer .links button:first-of-type {
    margin-right: 10px; /* Space between Cabinet and Logout buttons */
}

/* Logout Button (optional distinct style) */
footer .links .logout-btn {
    color: #fff;
}

footer .links .logout-btn:hover {
    color: #fff;
}
