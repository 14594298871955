/* General Styles */
.user-billings-page {
    background-color: #f8f9fa;
    text-align: center;
}

/* Button container */
.tab-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
}

.order-btn {
    background-color: transparent;
    border: 2px solid rgba(0, 123, 255, 0.7);
    color: rgba(0, 123, 255, 0.8);
    font-size: 16px;
    padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-family: inherit;
}

.order-btn:hover {
    background-color: rgba(0, 123, 255, 0.9);
    color: #ffffff;
    border-color: rgba(0, 123, 255, 1);
}

.order-btn.active {
    background-color: rgba(0, 123, 255, 1);
    color: #ffffff;
    border-color: rgba(0, 123, 255, 1);
}

.user-billings-content {
    padding: 40px 20px;
    background-color: white;
    position: relative; /* Ensure positioning for empty state messages */
    min-height: 400px; /* Ensure content area has sufficient height */
}

.user-billings-content h2 {
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
}

/* No Data Messages */
.no-orders-message,
.no-statements-message {
    font-size: 18px;
    color: #6c757d;
    margin: 20px 0;
    padding: 15px;
    background-color: #f1f3f5;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Pagination Styles */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.pagination button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #4CAF50;
    border: 1px solid #4CAF50;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.pagination button:hover {
    background-color: #0056b3;
}

.pagination .active-page {
    background-color: #0056b3;
}

.pagination button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}


/* Media Queries for Small Screens (Mobile) */
@media (max-width: 768px) {
    .user-billings-content h2 {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .no-orders-message,
    .no-statements-message {
        font-size: 16px;
        padding: 10px;
    }
}

@media (max-width: 520px) {
    .user-billings-content h2 {
        font-size: 16px;
        margin-bottom: 15px;
    }

    .no-orders-message,
    .no-statements-message {
        font-size: 16px;
        padding: 10px;
    }

    .order-btn {
        background-color: transparent;
        border: 2px solid rgba(0, 123, 255, 0.7);
        color: rgba(0, 123, 255, 0.8);
        font-size: 12px;
        padding: 7px 7px;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        font-family: inherit;
    }
    /* Button container */
    .tab-buttons {
        margin-bottom: 10px;
    }
    .user-billings-content {
        padding: 20px 20px;
        min-height: 200px; /* Ensure content area has sufficient height */
    }
}

/* Focus effect for accessibility */
.back-cabinet-btn:focus {
    box-shadow: 0 0 5px rgba(0, 150, 136, 0.7);
}
