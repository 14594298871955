/* General Page Styling */
.cabinet-page {
    background-color: #fff;
    color: #333;
    font-family: 'Helvetica Neue', sans-serif;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Cabinet Content Section */
.cabinet-content {
    max-width: 1300px;
    /*margin: 30px auto;*/
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    animation: fadeIn 0.5s ease-in-out;
}

/* ID Card Container for User and Home */
.id-container {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 30px;
    transition: all 0.3s ease;
}

/* Common ID Card Styles */
.id-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 75px;
    padding: 10px 80px 10px 80px;
    background-color: #f3f3f3;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease, padding 0.3s ease;
    cursor: pointer;
    width: 48%;
    min-height: 200px; /* Make the box taller */
    transition: all 0.3s ease-in-out;
}
.disable-hover-id-card{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 75px;
    padding: 10px 80px 10px 80px;
    background-color: #f3f3f3;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    width: 48%;
    min-height: 200px; /* Make the box taller */
}

.id-card:hover {
    transform: translate(5px, -5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/* Refresh Icon Styles */
.refresh-icon {
    display: inline-flex; /* Ensure it aligns properly with the text */
    align-items: center; /* Vertically center the icon */
    justify-content: center;
    margin-left: 8px; /* Add space between the text and icon */
    color: #007bff;
    cursor: pointer;
    transition: transform 0.5s ease, color 0.5s ease;
    vertical-align: middle; /* Align with text */
}

.refresh-icon:hover {
    color: #0056b3;
    transform: rotate(360deg); /* Optional rotation effect */
}


/* Image Styles */
.id-photo,
.home-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

.home-photo {
    border-radius: 10px;
}

.id-info {
    text-align: left;
}

.id-info h3 {
    font-size: 24px;
    margin-bottom: 8px;
    color: #333;
}

/* Status Text Alignment */
.id-info p {
    font-size: 16px;
    line-height: 24px; /* Match the icon's height dynamically */
    margin-bottom: 6px;
    color: #666;
    align-items: center; /* Vertically center text with icon */
}

.id-info a {
    color: #007bff;
    font-size: 16px;
    text-decoration: none;
    transition: color 0.3s ease;
}

.id-info a:hover {
    color: #0056b3;
}

/* User Menu Styling */
.user-menu {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    margin-top: 30px;
    flex-wrap: wrap;
}

/* Button Styling */
.menu-btn {
    background-color: transparent;
    border: 2px solid rgba(18, 22, 25, 0.7);
    color: rgba(18, 22, 25, 0.8);
    font-size: 16px;
    padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-family: inherit;
    width: 120px;
}

/* Button Hover Effect */
.menu-btn:hover {
    background-color: rgba(18, 22, 25, 0.9);
    color: #ffffff;
    border-color: rgba(18, 22, 25, 1);
}

/* Settings Button Specific */
.settings-btn {
    border-color: rgba(0, 123, 255, 0.7);
    color: rgba(0, 123, 255, 0.8);
}

.settings-btn:hover {
    background-color: rgba(0, 123, 255, 0.9);
    color: #ffffff;
    border-color: rgba(0, 123, 255, 1);
}

/* Logout Button Specific */
.logout-btn {
    border-color: rgba(255, 77, 77, 0.7);
    color: rgba(255, 77, 77, 0.8);
}

.logout-btn:hover {
    background-color: rgba(255, 77, 77, 0.9);
    color: #ffffff;
    border-color: rgba(255, 77, 77, 1);
}

.highlight-red {
    color: red !important;
    font-weight: bold;
}

.user-cabinet-popup-content input,
.user-cabinet-popup-content textarea,
.user-cabinet-popup-content select {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box; /* Ensure consistent sizing */
    background-color: #fafafa; /* Light background color */
    color: #333; /* Text color */
    cursor: pointer; /* Change cursor to pointer */
    transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transition for focus */
}

.user-cabinet-popup-content input:focus,
.user-cabinet-popup-content textarea:focus,
.user-cabinet-popup-content select:focus {
    outline: none; /* Remove outline on focus */
    border-color: #007bff; /* Change border color on focus */
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.2); /* Add box-shadow on focus */
}

.user-cabinet-popup-content select {
    background-color: #fafafa;;
    -webkit-appearance: none; /* Remove default styling for select */
    -moz-appearance: none;
    appearance: none;
    padding-right: 15px; /* Adjust padding for the select element */
}

.user-cabinet-popup-content select:focus {
    padding-right: 15px; /* Ensure padding is consistent on focus */
}

.user-cabinet-id-info {
    margin: 20px;
}

.user-cabinet-id-info h3,
.user-cabinet-id-info p {
    margin: 10px 0;
}


/* Popup background */
.user-cabinet-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: opacity 0.3s ease, visibility 0.3s;
}

.user-cabinet-popup.open {
    opacity: 1;
    visibility: visible;
}

/* Popup content */
.user-cabinet-popup-content {
    background: white;
    padding: 25px;
    border-radius: 12px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
    transition: transform 0.3s ease-in-out;
    transform: scale(0.95);
}

.user-cabinet-popup-content.open {
    transform: scale(1);
}

/* Header in the popup */
.user-cabinet-popup-content h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
}

/* Form elements */
.user-cabinet-popup-content form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}


/* Button container */
.user-cabinet-btn-container {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}



/* Mobile Responsiveness */
@media (max-width: 600px) {
    .user-cabinet-popup-content {
        padding: 20px;
    }

}

/* report bug styles end */

/* Smooth Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Design */
@media (max-width: 1000px) {
    .id-container {
        flex-direction: column;
        align-items: center;
    }

    .id-card {
        width: 90%;
        flex-direction: column;
        align-items: center;
        text-align: center;
        min-height: 250px; /* Make the ID boxes taller on mobile */
        padding: 20px; /* Reduce padding on mobile */
    }

    .disable-hover-id-card{
        width: 90%;
        flex-direction: column;
        align-items: center;
        text-align: center;
        min-height: 250px; /* Make the ID boxes taller on mobile */
        padding: 20px; /* Reduce padding on mobile */
    }

    .id-info {
        text-align: center;
    }

    .id-photo,
    .home-photo {
        margin-bottom: 15px;
        width: 120px;
        height: 120px;
    }
}


