.loaderCentFilter{
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes loaderWrap_ccw {
    0% {transform: rotate(0deg);}
    50% {transform: rotate(90deg) translateY(-500px);} /*1*/
}
.st3{fill:#007bff;}
.yellow_loaderWrapTwo{
    transform-origin: 0px 0px;
    animation-name: loaderWrap_ccw; /*1*/
}

@keyframes polygon_ccw {
    0% {transform: rotate(0deg);}
    50% {transform: rotate(90deg) translateX(500px);}/*2*/
}

.st2{fill:#F3F7FB;}
.red_loaderWrap{
    transform-origin: 500px 0px;
    animation-name: polygon_ccw; /*2*/
}

@keyframes loaderWrap_cw {
    0% {transform: rotate(0deg);}
    50% {transform: rotate(90deg) translateX(-500px);}/*3*/
}
.blue_loaderWrapOne{
    transform-origin: 0px 500px;
    animation-name: loaderWrap_cw; /*3*/
}

@keyframes loaderWrap_ccww {
    0% {transform: rotate(0deg);}
    50% {transform: rotate(90deg) translateY(500px);}/*4*/
}
.st4{fill:#007bff;}
.green_loaderWrapThree{
    transform-origin: 500px 500px;
    animation-name: loaderWrap_ccww;/*4*/
}


.svgLoader{
    position:absolute;
    left:50%;
    top:50%;
    margin:-250px 0 0 -170px;
}

.puzzle{
    transform-origin: 326px 163px;
    animation-name: puzzle;
}

polygon{
    stroke:#34495e;
    stroke-width: 7px;
}

polygon, .loaderWrap, .puzzle{
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-play-state: running;
}


.st0{display:none;}
.st1{display:inline;}


