/* Account Settings Container */
.account-settings {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
}

/* Form Container */
.settings-form {
    max-width: 800px; /* Adjust width for balanced layout */
    width: 100%;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Form Title */
.form-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center; /* Centers the title */
}

/* Grid for Inputs */
.form-grid {
    display: flex; /* Use flexbox for two-column layout */
    gap: 20px; /* Space between columns */
    padding-bottom: 20px;
}

/* Columns */
.form-column {
    flex: 1; /* Each column takes equal space */
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between input groups */
}

/* Input Group */
.input-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.input-group label {
    font-size: 14px;
    color: #666;
    font-weight: bold;
}

.input-group .MuiOutlinedInput-root {
    width: 100%;
    font-size: 16px;
}

/* Error Message Styling */
.error-message {
    color: red;
    font-size: 14px;
    margin-top: -10px;
}

/* Button Styling */
.save-btn {
    margin-top: 20px;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    background-color: #4CAF50;
    color: #fff;
    transition: background-color 0.3s ease;
}

.save-btn:hover {
    background-color: #45a049;
}

/* Responsiveness */
@media screen and (max-width: 768px) {
    .form-grid {
        flex-direction: column; /* Stack columns on smaller screens */
    }

    .form-column {
        width: 100%; /* Full width for each column */
    }

    .save-btn {
        font-size: 14px;
        padding: 10px;
    }
}
