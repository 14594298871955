/* Table adjustments for better layout */
.payment-history-table {
    width: 80%;
    max-width: 1000px;
    margin: 20px auto;
    border-collapse: collapse;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    table-layout: auto; /* Ensure all columns have equal width */
}
/* Ensure consistent column width */
.payment-history-table th,
.payment-history-table td {
    word-wrap: break-word;  /* Allow long words to break and wrap into the next line */
    white-space: normal;    /* Ensure text within cells can wrap normally */
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
    font-size: 14px;
    box-sizing: border-box;
}

/* Header Styling */
.payment-history-table th {
    background-color: #007bff;
    color: white;
    font-size: 16px;
    font-weight: bold;
}

/* Row Styling */
.payment-history-table tbody tr {
    background-color: #f8f9fa;
    transition: background 0.3s ease-in-out;
}

.payment-history-table tbody tr:nth-child(even) {
    background-color: #e9ecef;
}

.payment-history-table tbody tr:hover {
    background-color: #d6d6d6;
}

/* Body Styling */
.payment-history-table td {
    background-color: #f8f9fa;
    font-size: 16px;
}

.payment-history-table tr:nth-child(even) td {
    background-color: #e9ecef;
}

.payment-history-table tr:hover td {
    background-color: #d6d6d6;
}

/* Error Message */
.error-message {
    color: red;
    font-size: 16px;
    margin-top: 20px;
}

/* Pay Button Styling */
.download-button {
    background-color: #28a745;
    color: white;
    padding: 8px 6px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    transition: background 0.3s;
}

.download-button:hover {
    background-color: #218838;
}
/* Media Queries for Small Screens */
@media (max-width: 768px) {
    /* Make the table take up full width and adjust column sizes */
    .payment-history-table {
        width: 100%;
        box-shadow: none;
    }

    .payment-history-table th,
    .payment-history-table td {
        font-size: 12px; /* Smaller font size for better readability */
        padding: 8px;
    }
}

@media (max-width: 520px) {
    .user-billings-content h2 {
        font-size: 16px;
        margin-bottom: 15px;
    }

    /* Make the table take up full width and adjust column sizes */
    .payment-history-table {
        width: 100%;
    }

    .payment-history-table th,
    .payment-history-table td {
        font-size: 8px; /* Smaller font size for better readability */
        padding: 8px;
    }


    /* Ensure pagination buttons fit well on mobile */
    .pagination button {
        padding: 6px 12px; /* Smaller padding for buttons */
        font-size: 8px;
    }

    .pagination {
        margin: 10px 0;
    }

    /* Ensure the "back-cabinet-btn" is responsive */
    .back-cabinet-btn {
        padding: 8px 16px; /* Reduce padding */
        font-size: 10px;
    }
    /* Pay Button Adjustments */
    .download-button {
        font-size: 10px;
        padding: 6px 12px;
    }
}

@media (max-width: 320px) {

    /* Adjust the table headers and body */
    .payment-history-table th {
        font-size: 7px; /* Smaller font size for better readability */
        padding: 2px;
    }
    .payment-history-table td {
        font-size: 7px; /* Smaller font size for better readability */
        padding: 2px;
    }

}
