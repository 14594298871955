.contact-info {
    background-color: white;
    padding: 60px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-info h2 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #333;
}

.contact-info p {
    font-size: 18px;
    margin-bottom: 30px;
    color: #555;
}

.contact-form {
    max-width: 600px;
    margin: auto;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-form input,
.contact-form textarea,
.contact-form select {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box; /* Ensure consistent sizing */
    background-color: #fafafa; /* Light background color */
    color: #333; /* Text color */
    cursor: pointer; /* Change cursor to pointer */
    transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transition for focus */
}

.contact-form input:focus,
.contact-form textarea:focus,
.contact-form select:focus {
    outline: none; /* Remove outline on focus */
    border-color: #007bff; /* Change border color on focus */
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.2); /* Add box-shadow on focus */
}

.contact-form select {
    background-color: #fafafa;;
    -webkit-appearance: none; /* Remove default styling for select */
    -moz-appearance: none;
    appearance: none;
    padding-right: 15px; /* Adjust padding for the select element */
}

.contact-form select:focus {
    padding-right: 15px; /* Ensure padding is consistent on focus */
}

.contact-form button {
    background-color: #007bff;
    color: white;
    padding: 15px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition on hover */
}

.contact-form button:hover {
    background-color: #0056b3;
}

.thank-you-message {
    text-align: center;
    font-size: 20px;
    color: #007bff;
    margin-top: 20px;
}
