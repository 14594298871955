.equipment-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: left;
}

.equipment-container {
    max-width: 1200px;
    width: 100%;
}

.title {
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
}

.equipment-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Allow items to wrap to new lines */
}

.equipment-list {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.equipment-item {
    display: flex;
    align-items: center;
    padding: 10px;
    transition: transform 0.3s ease, filter 0.3s ease;
    cursor: pointer;
}

.equipment-item.hovered {
    transform: scale(1.05);
    filter: brightness(1.2);
}

.equipment-image {
    width: 80px;
    height: 80px;
    margin-right: 20px;
    transition: filter 0.3s ease;
}

.equipment-info {
    max-width: 600px;
}

.equipment-title {
    font-size: 1.2em;
    margin-bottom: 5px;
}

.equipment-description {
    font-size: 1em;
    color: #666;
}

.slider-container {
    flex: 0 0 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    margin-left: 20px;
    pointer-events: none; /* Prevent interactions */
}

.slider-wrapper {
    display: flex;
    flex-direction: row;
    transition: transform 0.5s ease;
    width: 100%;
}

.slider-item {
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.slider-image {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
}

.slider-text {
    font-size: 1em;
    color: #333;
}

/* Media query for smaller screens (phones and tiny screens) */
@media screen and (max-width: 768px) {
    .equipment-content {
        flex-direction: column; /* Stack elements vertically */
        align-items: center;
    }

    .slider-container {
        margin-top: 20px; /* Add some space between the list and slider */
        margin-left: 0;
        width: 100%; /* Ensure it takes the full width */
    }

    .slider-wrapper {
        width: 100%; /* Ensure it takes the full width */
    }

    .slider-item {
        width: 100%; /* Ensure slider items take full width */
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .slider-image {
        width: 90%; /* Adjust width to fit within the container */
        max-width: 250px; /* Limit the maximum size of the image */
        height: auto; /* Keep the aspect ratio */
        object-fit: contain; /* Ensure the image fits without being cropped */
    }
}
