/* General Styling for Settings Page */
.settings-page {
    background-color: #fff;
    color: #333;
    font-family: 'Helvetica Neue', sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.settings-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Spacing between child elements */
    padding: 20px;
    width: 100%;
}
/* Back to Cabinet Button */
.back-cabinet-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    align-self: center; /* Center horizontally within parent */
}

.back-cabinet-btn:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Navigation Buttons */
.settings-navigation {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 20px;
    flex-wrap: wrap; /* Wrap buttons for small screens */
    width: 100%;
}

.settings-btn {
    background-color: transparent;
    border: 2px solid rgba(0, 123, 255, 0.7);
    color: rgba(0, 123, 255, 0.8);
    font-size: 16px;
    padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-family: inherit;
}

.settings-btn:hover {
    background-color: rgba(0, 123, 255, 0.9);
    color: #ffffff;
    border-color: rgba(0, 123, 255, 1);
}

.settings-btn.active {
    background-color: rgba(0, 123, 255, 1);
    color: #ffffff;
    border-color: rgba(0, 123, 255, 1);
}

/* Content Section */
.settings-details {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Smooth Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Design */
@media (max-width: 820px) {
    .settings-navigation {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .settings-btn {
        width: 90%; /* Buttons span most of the screen */
        text-align: center;
    }

    .settings-details {
        padding: 15px;
    }

    .back-cabinet-btn {
        width: 90%; /* Button spans most of the screen */
        text-align: center;
    }
}

@media (max-width: 480px) {
    .settings-btn {
        font-size: 14px; /* Slightly smaller font for very small screens */
        padding: 8px 10px;
    }

    .back-cabinet-btn {
        font-size: 14px; /* Match the smaller buttons */
        padding: 8px 16px;
    }
}