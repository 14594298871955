.password-settings-container {

}

.password-settings-title {
    text-align: center;
    font-size: 1.75rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 30px;
}

.password-settings-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.password-input-group {
    width: 100%;
    position: relative;
}

.password-input-group .MuiTextField-root {
    width: 100%;
}

.password-update-btn {
    padding: 12px 25px;
    background-color: #1976d2;
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.password-update-btn:hover {
    background-color: #1565c0;
}

.password-update-btn:disabled {
    background-color: #b0bec5;
    cursor: not-allowed;
}

/*.password-input-group input:focus,*/
/*.password-input-group textarea:focus {*/
/*    outline: 2px solid #1976d2;*/
/*}*/

.password-input-group input::placeholder,
.password-input-group textarea::placeholder {
    font-style: italic;
    color: #888;
}

.password-input-error + .password-error-message {
    opacity: 1;
    max-height: 50px; /* Adjust max-height as needed */
}

.password-error-message {
    color: #d32f2f;
    font-size: 0.9rem;
    margin-top: 5px;
    opacity: 1;
    overflow: hidden;
    transition: opacity 0.3s ease, max-height 0.3s ease;
}

.password-success-message {
    color: #388e3c;
    font-size: 0.9rem;
    margin-top: 10px;
}

.password-settings-form .MuiTextField-root {
    margin-bottom: 15px;
}

@media (max-width: 768px) {
    .password-settings-container {

    }

    .password-settings-title {
        font-size: 1.5rem;
    }
}
