/* HeaderSection.css */

@import url(//fonts.googleapis.com/css?family=Lato:300:400);

.header {
    position:relative;
    text-align:center;
    background: linear-gradient(60deg, rgba(30,89,197,1) 0%, rgba(7,171,183,1) 100%);
    color:white;
}
/*.footer {*/
/*    margin-top: 250px;*/
/*}*/

.inner-header {
    height:15vh;
    width:100%;
    margin: 0;
    padding: 0;
}

.flex { /* Flexbox for header container */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.waves {
    position:relative;
    width: 100%;
    height:15vh;
    margin-bottom:-7px; /*Fix for safari gap*/
    min-height:100px;
    max-height:150px;
}

.parallax > use {
    animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
}
.parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}
.parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}
.parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}
.parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}

@keyframes move-forever {
    0% {
        transform: translate3d(-90px,0,0);
    }
    100% {
        transform: translate3d(85px,0,0);
    }
}

/* Shrinking for mobile */
@media (max-width: 768px) {
    .waves {
        height:40px;
        min-height:40px;
    }

    h1 {
        font-size:24px;
    }
}
