/* General Styles */
/* No Data Message */
.no-statements-message {
    font-size: 18px;
    color: #6c757d;
    margin: 20px 0;
    padding: 15px;
    background-color: #f1f3f5;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* Table Styling */
.order-details-table {
    width: 80%;
    max-width: 1000px;
    margin: 20px auto;
    border-collapse: collapse;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    table-layout: auto; /* Ensure all columns have equal width */
}

.order-details-table th,
.order-details-table td {
    word-wrap: break-word;  /* Allow long words to break and wrap into the next line */
    white-space: normal;    /* Ensure text within cells can wrap normally */
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
    font-size: 14px;
    box-sizing: border-box;
}

/* Table Header Styling */
.order-details-table th {
    background-color: #007bff;
    color: white;
    font-size: 16px;
    font-weight: bold;
}

/* Table Row Styling */
.order-details-table tbody tr {
    background-color: #f8f9fa;
    transition: background 0.3s ease-in-out;
}

.order-details-table tbody tr:nth-child(even) {
    background-color: #e9ecef;
}

.order-details-table tbody tr:hover {
    background-color: #d6d6d6;
}

/* Table Data Styling */
.order-details-table td {
    background-color: #f8f9fa;
    font-size: 16px;
}

.order-details-table tr:nth-child(even) td {
    background-color: #e9ecef;
}

.order-details-table tr:hover td {
    background-color: #d6d6d6;
}

/* Pay Button Styling */
.pay-button {
    background-color: #28a745;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background 0.3s;
}

.pay-button:hover {
    background-color: #218838;
}

/* Pagination Styles */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.pagination button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s;
}

.pagination button:hover {
    background-color: #0056b3;
}

.pagination .active-page {
    background-color: #0056b3;
}

.pagination button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* Media Queries for Small Screens */
@media (max-width: 768px) {
    .user-billings-content h2 {
        font-size: 20px;
        margin-bottom: 15px;
    }

    /* Make the table take up full width and adjust column sizes */
    .order-details-table {
        width: 100%;
        box-shadow: none;
    }

    .order-details-table th,
    .order-details-table td {
        font-size: 12px; /* Smaller font size for better readability */
        padding: 8px;
    }

    /* Pagination Buttons Adjustments */
    .pagination button {
        padding: 6px 12px; /* Smaller padding for buttons */
        font-size: 12px;
    }

    .pagination {
        margin: 10px 0;
    }

    /* Adjust the no-statements-message */
    .no-statements-message {
        font-size: 16px;
        padding: 10px;
    }
}

@media (max-width: 520px) {
    .user-billings-content h2 {
        font-size: 16px;
        margin-bottom: 15px;
    }

    /* Table Adjustments */
    .order-details-table {
        width: 100%;
    }

    .order-details-table th,
    .order-details-table td {
        font-size: 8px; /* Smaller font size for better readability */
        padding: 8px;
    }

    /* Pagination Buttons Adjustments */
    .pagination button {
        padding: 6px 12px;
        font-size: 8px;
    }

    .pagination {
        margin: 10px 0;
    }

    /* Adjust the no-statements-message */
    .no-statements-message {
        font-size: 14px;
        padding: 8px;
    }

    /* Pay Button Adjustments */
    .pay-button {
        font-size: 10px;
        padding: 6px 12px;
    }
}
@media (max-width: 320px) {

    .order-details-table th,
    .order-details-table td {
        font-size: 7px; /* Smaller font size for better readability */
        padding: 2px;
    }

}