.terms-of-service-page {
    font-family: 'Helvetica Neue', sans-serif;
    background-color: #f9f9f9;
    /*padding: 20px;*/
    color: #333;
}

.terms-content {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.terms-content h2 {
    text-align: center;
    margin-bottom: 20px;
}

.terms-content h3 {
    margin-top: 20px;
    color: #333;
}

.terms-content p {
    margin-bottom: 15px;
}
