/* Reset and base styles */
body, h1, h2, h3, p, a {
    margin: 0;
    padding: 0;
    font-family: 'Helvetica Neue', sans-serif;
    color: #333;
}

.homeHeaderTitle {
    margin: 0;
    padding: 0;
    font-family: 'Helvetica Neue', sans-serif;
    color: #fff;
    font-size: 48px;
}
.service-header {
    color: #fff;
}

.homepage {
    text-align: center;
    background-color: #f8f9fa;
}

/* Hero Section */
.hero-section {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://t4.ftcdn.net/jpg/05/71/83/47/360_F_571834789_ujYbUnH190iUokdDhZq7GXeTBRgqYVwa.jpg') no-repeat center center/cover;
    color: white;
    padding: 150px 20px;
}

.hero-content {
    max-width: 800px;
    margin: auto;
}

.hero-content h1 {
    font-size: 48px;
    margin-bottom: 20px;
}

.hero-content p {
    font-size: 20px;
    margin-bottom: 30px;
}

.cta-button {
    background-color: #007bff;
    color: white;
    padding: 15px 30px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    font-size: 18px;
}

.cta-button:hover {
    background-color: #0056b3;
}

/* About Us Section */
.about-us {
    background-color: white;
    padding: 80px 20px;
}

.about-content {
    max-width: 800px;
    margin: auto;
}

.about-content h2 {
    font-size: 32px;
    margin-bottom: 20px;
}

.about-content p {
    font-size: 18px;
    line-height: 1.6;
}

/* What We Do Section */
.what-we-do {
    background-color: #e9ecef;
    padding: 80px 20px;
}

.services-intro h2 {
    font-size: 32px;
    margin-bottom: 20px;
}

.services-intro p {
    font-size: 18px;
    margin-bottom: 50px;
}

/* Order Now Section */
.order-now {
    background: linear-gradient(
            rgba(0, 123, 255, 0.8), /* Apply the #07ABB7FF color with 80% opacity */
            rgba(0, 0, 0, 0.6)
    ),
    url('../pageComponents/img/bacgroundImg.png') no-repeat center center/cover;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: white;
    padding: 100px 20px;
    transition: background 0.3s ease-in-out;
}

.order-content h2 {
    font-size: 32px;
    margin-bottom: 20px;
}

.order-content p {
    font-size: 18px;
    margin-bottom: 30px;
}

.order-now .cta-button {
    background-color: white;
    color: #007bff;
    padding: 15px 30px;
    font-size: 18px;
    border-radius: 5px;
    display: inline-block;
    transition: background-color 0.3s ease-in-out; /* Smooth transition for hover effects */
}

.order-now .cta-button:hover {
    background-color: #e9ecef;
    color: #007bff;
}

/* Responsive Adjustments for Smaller Screens */
@media (max-width: 768px) {
    .order-now {
        background-attachment: scroll; /* Fix for mobile scrolling performance */
        padding: 60px 20px;
        text-align: center;
    }

    .order-content h2 {
        font-size: 28px;
    }

    .order-content p {
        font-size: 16px;
    }

    .order-now .cta-button {
        padding: 12px 25px;
        font-size: 16px;
    }
}

/* Contact Section */
.contact-info {
    background-color: white;
    padding: 80px 20px;
}

.contact-info h2 {
    font-size: 32px;
    margin-bottom: 20px;
}

.contact-info p {
    font-size: 18px;
    margin-bottom: 30px;
}

.contact-form {
    max-width: 600px;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.contact-form input, .contact-form textarea {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
}

.contact-form button {
    background-color: #007bff;
    color: white;
    padding: 15px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.contact-form button:hover {
    background-color: #0056b3;
}

.contact-details {
    margin-top: 50px;
    font-size: 18px;
}

/* Tiles Section */
.tiles-section {
    padding: 40px 20px;
    background-color: #f8f9fa;
    display: flex;
    justify-content: center;
}

/* Container for the logo */
.logo-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

/* Logo Image */
.logo {
    transition: transform 0.3s ease, filter 0.3s ease; /* Smooth transition for hover */
}

/* Popup text that will appear on hover */
.login-popup {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -120%); /* Center horizontally and move above the logo */
    margin-top: 0px; /* Adjust distance from logo */
    background-color: white;
    border: 2px solid #007bff;
    padding: 15px;
    width: 200px;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
    border-radius: 5px;
    z-index: 1;
}

/* Media query for phones */
@media (max-width: 600px) {
    .login-popup {
        margin-top: 10px; /* Adjusted margin for smaller screens */
        transform: translate(-50%, -110%); /* Adjust position slightly for phones */
    }
}

/* Text styles inside the popup */
.login-title {
    font-size: 18px;
    font-weight: bold;
    color: #007bff;
    margin-bottom: 5px; /* Space between title and description */
}

.login-description {
    font-size: 14px;
    color: #555;
    line-height: 1.4; /* More spacing between the lines */
}

/* Open door/window effect on hover */
.logo-container:hover .logo {
    transform: scale(1.25); /* Zoom in for effect */
    filter: brightness(1.1); /* Make the image brighter */
}

/* Show the popup with smooth transition */
.logo-container:hover .login-popup {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, -130%); /* Popup rises slightly */
}

/* Optional - Add a transition for the popup itself for a smooth entry */
.login-popup {
    transform: translateX(-50%) translateY(30px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

