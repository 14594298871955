@font-face {
    font-family: 'Poppins-SemiBold';
    src: url("components/pageComponents/fonts/Poppins-SemiBold.ttf");
    src: local("Poppins-SemiBold"), local("Poppins-SemiBold"),
    url("components/pageComponents/fonts/Poppins-SemiBold.ttf");

    font-weight: 600;
}
@font-face {
    font-family: 'Poppins-Light';
    src: url("components/pageComponents/fonts/Poppins-Light.ttf");
    src: local("Poppins-Light"), local("Poppins-Light"),
    url("components/pageComponents/fonts/Poppins-Light.ttf");

    font-weight: 300;
}

* {
    font-family: "Poppins-Light", sans-serif;
}

html {
    scroll-behavior: smooth;
}

/*.logo {*/
/*    position: fixed;*/
/*    top: 30px;*/
/*    right: 30px;*/
/*    display: block;*/
/*    z-index: 100;*/
/*    transition: all 250ms linear;*/
/*}*/