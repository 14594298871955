.privacy-policy-page {
    background-color: #f9f9f9;
    color: #333;
    font-family: 'Helvetica Neue', sans-serif;
    /*padding: 20px;*/
}

.privacy-content {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.privacy-content h2 {
    font-size: 32px;
    margin-bottom: 20px;
}

.privacy-content h3 {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 10px;
}

.privacy-content h4 {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.privacy-content p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
}

.privacy-content a {
    color: #007bff;
    text-decoration: none;
}

.privacy-content a:hover {
    text-decoration: underline;
}
